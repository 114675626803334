import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import styles from "./index.module.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import SEOComponent from "../../components/Shared/Seo";

const labels = [
  {
    subHeading: "Wichtige Voraussetzung",
    para:
      "Sie sind bzw. Ihre Firma ist Eigentümer der zu verkaufenden Waren. Der Eigentümer, Kontoinhaber (Bank oder PayPal) und die im Checkout angegebene Person/Firma müssen ident sein. Sie versteuern Ihre Einkünfte selbst und betreiben keinen gewerbsmäßigen Handel. Privatverkäufer müssen volljährig und voll geschäftsfähig sein. Sie erklären sich bereit eine Kopie eines gültigen, amtlichen Lichtbildausweises an uns zu übermitteln. Ihre Ausweisdaten werden streng vertraulich behandelt und nicht weitergegeben.",
  },
  {
    subHeading: "Preise",
    para: (
      <>
        Bei den veröffentlichten Ankaufspreisen handelt es sich um Nettopreise.
        <br />
        DIE ONLINE-PREISE GELTEN AUSSCHLIESSLICH FÜR ONLINE-VERKÄUFE! BEI
        ANLIEFERUNG OHNE ONLINE-ABWICKLUNG KEINE PREISGARANTIE!
      </>
    ),
  },
  {
    subHeading: "Die Rolle der Schrott24at Website",
    para:
      "Die Schrott24 GmbH betreibt das Portal Schrott24at für die im Impressum genannten Zielregionen sowie kauft bzw. vermittelt – abhängig vom Handelsstatus und Standort des Verkäufers – den Ankauf von Waren – wie in den Ankaufsbeschreibungen definiert – und tritt im folgenden als Käufer der Waren bzw. vollumfänglich haftender Vermittler des Warenankaufs an Dritte sowie Ansprechpartner für alle Belange auf. Sowohl Ankaufsbestätigungen als auch Gutschriften werden – bei Haftungsübernahme durch die Schrott24 GmbH – seitens des jeweiligen Geschäftspartners ausgestellt.",
  },
  {
    subHeading:
      "Warenversand und Speditionsabholung, Preis-Garantie, -Erhöhung und -Minderung, Storno",
    para: (
      <>
        Die Schrott24 GmbH verpflichtet sich bzw. Dritte, übersandte Waren
        (Paketversand oder Spedition) innerhalb von 4 Werktagen (Mo-Fr,
        Wochenende und Feiertage werden nicht mitgezählt) nach Verkaufsabschluß
        im Shop zum vereinbarten Kilopreis anzunehmen (Sie erhalten eine
        Bestätigungs-Email nach Abschluß des Verkaufsvorgangs) sowie im Falle
        der Eigenanlieferung innerhalb von 2 Werktagen nach Verkaufsabschluß im
        Shop zum vereinbarten Kilopreis anzunehmen. Danach sind die Schrott24
        GmbH bzw. Dritte berechtigt die Ware zu den aktuell veröffentlichten
        Tagespreisen anzukaufen. Dies kann sich sowohl in Preiserhöhungen als
        auch in Preisminderungen des Ankaufspreis auswirken.
        <br />
        <br />
        Darüber hinaus sind Änderungen am Ankaufspreis nur dann möglich, wenn
        der Zustand der eingesendeten Ware nicht der vom Verkäufer gewählten
        Materialsorte entspricht. In solchen Fällen wird die Schrott24 GmbH mit
        dem Verkäufer der Ware in Kontakt treten und klären, ob die Ware auf
        Kosten des Verkäufers zurückgesendet, ggf. im Preis gemindert, teilweise
        oder ganz angekauft oder von der Schrott24 GmbH entsorgt werden soll.
        Irrtum sowie technische Fehler bei der Preisdarstellung (z.B.
        Kommafehler) vorbehalten.
        <br />
        <br />
        Durch Klick auf den Button „Verkauf abschließen“ im
        Schrott24-Onlineportal kommt ein verbindliches Rechtsgeschäft zwischen
        dem Verkäufer (Kunde) und der Schrott24 GmbH zustande. Eine Stornierung
        des Vorgangs kann bis 12 Stunden nach Durchführung des
        Online-Verkaufsvorgangs kostenlos vom Kunden vorgenommen werden. Der
        Kunde muss dazu formlos sein Begehren mitteilen, z.B: per Email oder
        telefonisch. Kommt das Geschäft nach dieser Frist nicht zu Stande, ist
        die Schrott24 GmbH berechtigt dem Kunden eine Gebühr in Höhe von 5% des
        Warenwerts der im Online-Verkaufsvorgangs angegeben wurde zu verrechnen.
        Sofern der Warenwert der im Online-Verkaufsvorgangs angegeben wurde
        weniger als EUR 400 beträgt, gilt eine Stornogebühr in der Höhe von EUR
        20,00 als vereinbart.
      </>
    ),
  },
  {
    subHeading: "Bezahlung der Ware",
    para: (
      <>
        Sie können bei der Verkaufsabwicklung zwischen den beiden
        Auszahlungsmethoden Banküberweisung und Auszahlung per PayPal wählen.
        Die Schrott24 GmbH verpflichtet sich bzw. Dritte, die Überweisung des
        vereinbarten Betrages innerhalb von 48 Stunden (Samstage, Sonntage und
        Feiertage werden nicht gezählt) nach Erhalt der Ware – direkt oder
        seitens Dritter – auf das angegebene PayPal oder Bankkonto des
        Verkäufers zu überweisen bzw. eine Überweisung zu veranlassen.
        <br />
        <br />
        PayPal behält sich bei Überweisungen 2% des Überweisungsbetrages ein.
        Sollten sie diese Auszahlungsmethode gewählt haben, müssen wir diese
        Kosten leider an Sie weitergeben. Ihr Auszahlungsbetrag verringert sich
        dadurch um die anfallende Gebühr i.H.v. 2%.
      </>
    ),
  },
  {
    subHeading:
      "Versand, Paketmarken, Versandkosten, Paketgröße und Sortentrennung",
    para: (
      <>
        Wählen Sie als Versandoption Eigenversand aus haben Sie grundsätzlich
        die Möglichkeit die vereinbarte Ware persönlich an der jeweils
        angegebenen Anlieferungsadresse abzugeben. Bei der Anlieferung müssen
        Sie die die Verkaufsbestätigung sowie einen gültigen amtlichen
        Lichtbildausweis (z.B. Führerschein, Personalausweis, Reisepass)
        vorlegen. Darüber hinaus haben Sie die Übergabe der Ware durch
        Unterschrift einer Übernahmebestätigung zu bestätigen. Wenn Sie die Ware
        nicht persönlich anliefern, benötigt der Lieferant einen amtlichen
        Lichtbildausweis.
        <br />
        <br />
        Neben Eigenversand bieten wir in unserem Webshop den Versand per
        vergünstigter Post-Versandmarke an. Wir ziehen Ihnen für die Nutzung
        (nur für aufgegebene Pakete) der zur Verfügung gestellten Post-Marken
        das jeweils aufgeführte Entgelt je Paket vom Verkaufserlös ab (für
        Österreich sind das 6,- EUR). Das maximale Gewicht bei Nutzung der
        Versandmarke darf 31,5 kg nicht übersteigen und darf folgende
        Abmessungen nicht überschreiten:
        <br />
        Die Länge des Paketes darf maximal 200cm betragen. Das Gurtmaß (Länge +
        Umfang) darf maximal 360 cm betragen.
        <br />
        <br />
        Verschiedene Materialsorten (z.B. Kupfer schwer, Kupfer-Kabel und
        Messing) können in einem Paket an uns gesendet werden, sofern nach der
        Öffnung eine einfach Trennung möglich ist (Verwenden Sie z.B. Karton
        oder Plastiktüten für die Separierung). Bitte verwenden Sie geeigentes
        (stabiles) Verpackungsmaterial. Um die Post-Versandmarke zu nutzen,
        wählen Sie während des Verkaufvorgangs in unserem Shop einfach die
        entsprechende Option. Sie erhalten nach Bearbeitung durch einen unserer
        Mitarbeiter eine Mail mit der Versandmarke zum Ausdrucken. (ACHTUNG:
        Bitte verwenden Sie nicht die Kopier- bzw. Duplizierfunktion Ihres
        Druckers, da Sie andernfalls Postwertzeichen vervielfältigen und im
        Verlustfall kein Versicherungsschutz für entsprechende Pakete besteht.)
      </>
    ),
  },
  {
    subHeading: "Speditionsabholung – österreichweit, schnell, günstig",
    para: (
      <>
        Für Warenlieferungen größeren Umfangs (empfohlen ab ca. 200 kg) bieten
        wir Ihnen österreichweit die Abholung per Spedition an. Die Ware muß
        hierfür auf Standard Einweg-/Mehrweg-Paletten ordnungsgemäß gepackt und
        entsprechend gesichert sein (Mehrweg-Paletten werden nicht vergütet).
        Die Regellaufzeit beträgt bei Speditionsabholung 24-48 Stunden. Um die
        Speditionsabholung zu nutzen, wählen Sie während des Verkaufsvorgangs in
        unserem Shop die entsprechende Option. Nach dem Verkaufsvorgang setzt
        sich ein Mitarbeiter mit Ihnen in Verbindung um einen Abholtermin zu
        vereinbaren und informiert Sie über die anfallenden Kosten. Bei Nutzung
        der Speditionsabholung ziehen wir Ihnen abhängig von Gewicht und
        Abholungsort das vereinbarte Entgelt vom Verkaufserlös ab (der Betrag
        wird gesondert auf der Gutschrift ausgewiesen).
        <br />
        <br />
        Bitte verwenden Sie für Einsendungen die in der Verkaufsbestätigung
        angegebene Lieferadresse.
        <br />
      </>
    ),
  },
  {
    subHeading: "Anlieferungsfrist",
    para: (
      <>
        Erfolgt die Anlieferung des verkauften Materials (Übergabe an den
        Logistikpartner) nicht innerhalb von 14 Tagen ab Versenden der
        Verkaufsbestätigung so wird Ihr Verkauf gebührenpflichtig storniert.
        Darüber hinaus behält sich die Schrott24 GmbH das Recht vor wenn nach 14
        Tagen ab Versenden der Verkaufsbestätigung keine Anlieferung erfolgt,
        die Annahme der Ware zu verweigern und vom Kaufvertrag zurück zu treten.
      </>
    ),
  },
  {
    subHeading: "Beschaffenheit der Ware",
    para:
      "Es erfolgt ausschließlich die Annahme von chargierfähigem Material. Der Kunde ist verpflichtet das Material entsprechend anzuliefern. Im Falle der Anlieferung von nicht chargierfähigem Material wird der entstehende Mehraufwand (z.B: Schweißen) dem Kunden verrechnet bzw. die Annahme verweigert. Chargierfähig bedeutet, dass die einzelnen Teile der angelieferten Ware nicht größer als 1,5 m x 1,5 m x 1,5 m sein dürfen.",
  },
];

const AgbPage = () => {
  const domain = useContext(DomainContext);
  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <SEOComponent page="agb" />
      <NavMenu />
      <div className={styles.titleSection}>
        <div className={styles.wContainer}>
          <h1 className={styles.heading1}>AGB</h1>
          <h2 className={styles.heading2}>Allgemeine Geschäftsbedingungen</h2>
        </div>
      </div>
      <div className={styles.defaultContainer}>
        <div>
          {labels.map(item => (
            <>
              <h2 className={styles.subHeading}>{item.subHeading}</h2>
              <p className={styles.para}>{item.para}</p>
            </>
          ))}
        </div>
      </div>
      <Footer domain={domain} />
      <GroupLegal />
    </I18nextProvider>
  );
};

export default AgbPage;
